<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    modelValue: boolean | string,
    disabled?: boolean,
    label: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean | string): void,
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val ?? false)
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }
</script>

<template>
  <div class="flex items-center space-x-2">
    <input
      :value="value"
      :disabled="disabled"
      type="checkbox"
      class="cursor-pointer"
      @input="onInput">
    <label class="form-label">{{ label }}</label>
  </div>
</template>
