import type { App } from 'vue'
import FaIcon from './fa-icon.vue'
import AppImage from './app-image.vue'
import AppAnimatedDot from './app-animated-dot.vue'
import AppAvatar from './app-avatar.vue'
import AppButton from './app-button.vue'
import AppCheckbox from './app-checkbox.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppInputPassword from './app-input-password.vue'
import AppInput from './app-input.vue'
import AppPagination from './app-pagination.vue'
import AppSearch from './app-search.vue'
import AppSelect from './app-select.vue'
import AppToast from './app-toast.vue'
import AppTableEmpty from './app-table-empty.vue'
import AppTable from './app-table.vue'
import AppModalHeadless from './app-modal-headless.vue'
import AppLogoMain from './app-logo-main.vue'

export default {
  install (app: App) {
    app.component('FaIcon', FaIcon)
    app.component('AppImage', AppImage)
    app.component('AppAnimatedDot', AppAnimatedDot)
    app.component('AppAvatar', AppAvatar)
    app.component('AppButton', AppButton)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppInput', AppInput)
    app.component('AppModal', AppModalHeadless)
    app.component('AppPagination', AppPagination)
    app.component('AppSearch', AppSearch)
    app.component('AppSelect', AppSelect)
    app.component('AppToast', AppToast)
    app.component('AppTableEmpty', AppTableEmpty)
    app.component('AppTable', AppTable)
    app.component('AppLogoMain', AppLogoMain)
  },
}

declare module 'vue' {
  export interface GlobalComponents {
    FaIcon: typeof FaIcon,
    AppAnimatedDot: typeof AppAnimatedDot
    AppAvatar: typeof AppAvatar
    AppButton: typeof AppButton
    AppCheckbox: typeof AppCheckbox
    AppErrorMsg: typeof AppErrorMsg
    AppImage: typeof AppImage
    AppInputPassword: typeof AppInputPassword
    AppSearch: typeof AppSearch
    AppInput: typeof AppInput
    AppModalHeadless: typeof AppModalHeadless
    AppPagination: typeof AppPagination
    AppSelect: typeof AppSelect
    AppToast: typeof AppToast
    AppTableEmpty: typeof AppTableEmpty
    AppTable: typeof AppTable
    AppLogoMain: typeof AppLogoMain
  }
}
