<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = withDefaults(
    defineProps<{
      src?: string | null,
      alt?: string,
      imgclass?: string,
      type?: 'person' | 'default',
    }>(),
    {
      src: undefined,
      alt: undefined,
      imgclass: undefined,
      type: 'default',
    },
  )

  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>
<template>
  <div>
    <div
      v-if="!src || error"
      class="h-full w-full flex items-center justify-center bg-gray-100 text-gray-400">
      <fa-icon
        v-if="type === 'person'"
        icon="solar:user-linear"
        style="width: 80%; height: auto; margin-top: 0.5rem" />
      <fa-icon
        v-else
        icon="basil:image-outline"
        class="h-[210px] w-[320px]" />
    </div>
    <img
      v-else
      class="h-full w-full object-cover"
      :class="imgclass"
      :src="src"
      :alt="alt ?? ''"
      @error="error = true">
  </div>
</template>
