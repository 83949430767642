<script setup lang="ts"></script>

<template>
  <tr>
    <td colspan="9999">
      <div class="h-64 flex items-center justify-center text-gray-500">
        Tidak ada data...
      </div>
    </td>
  </tr>
</template>
