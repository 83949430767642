<script setup lang="ts">
  import type { SelectHTMLAttributes } from 'vue'
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string,
    modelValue: SelectHTMLAttributes['value'],
    icon?: string,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    error?: string[],
    placeholder?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: typeof props.modelValue): void,
    (e: 'change', value: typeof props.modelValue): void,
  }>()

  const computedValue = computed({
    set: (value) => {
      emit('update:modelValue', value)
      emit('change', value)
    },
    get: () => props.modelValue,
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div>
    <label
      v-if="label"
      class="form-label"
      :for="inputId">
      {{ label }}
      <span
        v-if="required"
        class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center">
      <select
        :id="inputId"
        v-model="computedValue"
        :required="required"
        class="form-input cursor-pointer appearance-none pr-8"
        :disabled="disabled">
        <option
          v-if="placeholder"
          :value="null"
          hidden>
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>

      <div class="absolute right-2 top-2 z-10 text-gray-500">
        <fa-icon
          icon="mdi:chevron-down"
          class="w-4" />
      </div>
    </div>
    <app-error-msg :value="error" />
  </div>
</template>

<style lang="postcss" scoped></style>
