export function stringToHslColor(str: string) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  hash = Math.abs(hash)

  const h = hash % 360
  const s = 50 + (hash % 50)
  const l = 40 / 100
  return { h, s, l }
}

export function stringToHexColor(str: string) {
  const { h, s, l } = stringToHslColor(str)

  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `${f(0)}${f(8)}${f(4)}`
}

export const avatarGenerator = (
  name: string,
  background?: string,
  color?: string,
) => {
  const bg = '&background=' + (background ?? stringToHexColor(name))
  const cl = '&color=' + (color ?? 'fff')
  const nm = '&name=' + name
  return 'https://ui-avatars.com/api/?rounded=true' + bg + cl + nm
}
