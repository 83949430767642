<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    message?: string | string[],
  }>()

  const text = computed(() => {
    // null / empty string / empty array
    if (!props.message || props.message.length === 0) {
      return null
    }

    // array
    if (Array.isArray(props.message)) {
      return props.message[0]
    }

    // string
    return props.message
  })
</script>

<template>
  <div
    v-if="text"
    class="error-message">
    {{ text }}
  </div>
</template>

<style lang="postcss" scoped>
  /* used in api.ts scrolltoview on error 422 */
  .error-message {
    @apply scroll-mt-64 rounded py-1 text-xs text-red-500;
  }
</style>
