<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = defineProps<{
    modelValue: string,
    label?: string,
    maxLength?: number,
    placeholder?: string,
    required?: boolean,
    error?: string[],
    disabled?: boolean,

  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
  }>()

  const model = computed({
    set: (val) => {
      emit('update:modelValue', val)
    },
    get: () => props.modelValue,
  })

  const showPassword = ref(false)
</script>

<template>
  <div class="relative">
    <app-input
      v-model="model"
      :label="label"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      :disabled="disabled"
      :error="error"
      :required="required"
      :max-length="maxLength"
      autocomplete="password" />
    <app-button
      title="Tampilkan password"
      class="absolute right-3 top-1/2"
      :class="{ '-translate-y-3': error }"
      @click="showPassword = !showPassword">
      <fa-icon
        v-if="!showPassword"
        class="w-4"
        icon="mdi:eye-outline" />
      <fa-icon
        v-else
        class="w-4"
        icon="mdi:eye-off-outline" />
    </app-button>
  </div>
</template>

