<script setup lang="ts">
  import { nextTick, ref } from 'vue'

  interface Props {
    title: string,
    size?: 'medium' | 'large',
  }

  withDefaults(defineProps<Props>(), {
    size: 'medium',
  })

  const display = ref(false)
  const dim = ref(false)

  const onEscapeListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close()
    }
  }

  const open = () => {
    dim.value = true
    nextTick(() => {
      display.value = true
    })

    document.addEventListener('keydown', onEscapeListener)
  }

  const close = () => {
    display.value = false
    document.removeEventListener('keydown', onEscapeListener)
  }

  const afterLeave = () => {
    dim.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <div>
      <div
        v-if="dim"
        class="fixed inset-0 z-40 flex items-center justify-center">
        <div
          class="fixed inset-0 bg-black bg-opacity-40"
          @click="close"></div>
        <transition
          name="modal"
          @after-leave="afterLeave">
          <div
            v-if="display"
            class="z-50 max-h-full w-full flex p-4"
            :class="{'max-w-lg': size === 'medium', 'max-w-3xl': size === 'large'}">
            <div class="flex flex-1 flex-col rounded-xl bg-white px-4 py-2 sm:px-8 sm:py-4">
              <slot name="header">
                <h2
                  v-if="title"
                  class="text-center text-xl font-bold">
                  {{ title }}
                </h2>
              </slot>

              <div class="my-4 flex-1 overflow-y-auto pr-2 -mr-2">
                <slot></slot>
              </div>

              <slot name="footer"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
      transition: opacity 0.3s ease, margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
      margin-top: -50px;
      opacity: 0;
  }
</style>
