<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    modelValue: string,
    placeholder?: string,
    loading?: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
    (e: 'submit', value: string): void,
  }>()

  const text = computed({
    set: (value) => {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div class="relative">
    <app-input
      v-model="text"
      type="search"
      :placeholder="placeholder ?? 'Cari...'"
      @keyup.enter="emit('submit', text)" />
    <div
      class="absolute inset-y-0 flex items-center px-4 text-sm text-gray-500">
      <fa-icon
        v-if="loading"
        icon="mdi:loading"
        class="w-4 animate-spin" />
      <fa-icon
        v-else
        icon="mdi:magnify"
        class="w-4" />
    </div>
    <div
      class="absolute inset-y-0 right-2 flex items-center text-xs text-gray-500">
      <button
        v-if="text"
        type="button"
        class="z-10 h-6 w-6 flex items-center justify-center rounded-full hover:bg-gray-200"
        @click="text = ''">
        <fa-icon
          icon="mdi:close"
          class="w-4" />
      </button>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() {
    input {
      @apply pl-11 pr-8;
    }
  }
</style>
