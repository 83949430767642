import 'virtual:uno.css'
import '@unocss/reset/tailwind.css'
import './styles/main.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import type { Router } from 'vue-router'
import globalComponents from '@/components'
import globalDirectives from '@/directives'
import App from './App.vue'
import router from './router'

const app = createApp(App)
const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = router
})

app.use(pinia)
app.use(globalComponents)
app.use(globalDirectives)
app.use(router)

app.mount('#app')