import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/home/page-home.vue'),
        },
        {
          path: 'pengguna',
          name: 'user',
          component: () => import('@/views/user/page-user.vue'),
        },
        {
          path: 'template',
          name: 'theme',
          component: () => import('@/views/theme/page-theme.vue'),
        },
        {
          path: 'rsvp',
          name: 'rsvp',
          component: () => import('@/views/rsvp/page-rsvp.vue'),
          children: [
            {
              path: 'list',
              name: 'rsvp list',
              component: () => import('@/views/rsvp/rsvp-list/page-rsvp-list.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/layouts/auth/layout-auth.vue'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('@/views/login/page-login.vue'),
        },
      ],
    },
  ],
})

export default router
