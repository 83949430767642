<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { fmt } from '@/functions'

  interface Props {
    modelValue?: Pagination,
    scrollStatus?: boolean,
    customNamePage?: string,
    align?: string,
    labelTop?: boolean,
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    scrollStatus: true,
    customNamePage: 'page',
    align: 'end',
    labelTop: false,
  })

  const route = useRoute()
  const currentPage = computed(() => props.modelValue?.current_page ?? 0)
  const lastPage = computed(() => props.modelValue?.last_page ?? 0)
  const routePage = computed(() => route.query[props.customNamePage])
  const pages = computed(() => {
    const pages = [currentPage.value]
    let i = 1
    while (pages.length < 5 && pages.length < lastPage.value) {
      if (currentPage.value - i > 0) {
        pages.unshift(currentPage.value - i)
      }
      if (currentPage.value + i <= lastPage.value) {
        pages.push(currentPage.value + i)
      }
      i++
    }
    return pages
  })

  watch(routePage, () => {
    if (props.scrollStatus) {
      window.scrollTo(0, 0)
    }
  })

  const getRoute = (p: number | string) => {
    const page = p === 1 ? undefined : p
    return { ...route, query: { ...route.query, [props.customNamePage]: page } }
  }
</script>

<template>
  <nav
    v-if="modelValue && lastPage > 1 && currentPage <= lastPage"
    role="navigation"
    aria-label="Pagination Navigation">
    <div
      :class="
        labelTop
          ? 'flex flex-col items-center gap-4'
          : 'flex items-center'
      ">
      <div class="hidden text-xs md:block">
        Menampilkan
        <span class="font-semibold">
          {{ fmt.number(modelValue.from) }}-{{ fmt.number(modelValue.to) }}
        </span>
        dari
        <span class="font-semibold">{{ fmt.number(modelValue.total) }}</span>
      </div>
      <div :class="align === 'end' ? 'ml-auto flex' : 'mx-auto flex'">
        <router-link
          v-if="pages[0] - 1 >= 1"
          :to="getRoute(pages[0] - 1)"
          class="mr-1 border rounded-lg bg-primary-4 p-2 text-center text-xs hover:bg-gray-100"
          style="min-width: 2rem">
          <fa-icon
            icon="ic:round-chevron-left"
            class="w-4" />
        </router-link>
        <router-link
          v-for="page in pages"
          :key="page"
          :to="getRoute(page)"
          class="mr-1 border rounded-lg p-2 text-center text-xs"
          style="min-width: 2rem"
          :class="{
            'bg-primary-3 text-primary-1 hover:bg-primary-500':
              currentPage === page,
            'bg-white text-primary-1 hover:bg-primary-5': currentPage !== page,
          }">
          {{ fmt.number(page) }}
        </router-link>
        <router-link
          v-if="pages[pages.length - 1] + 1 <= lastPage"
          :to="getRoute(pages[pages.length - 1] + 1)"
          class="mr-1 border rounded-lg bg-white p-2 text-center text-xs hover:bg-gray-100"
          style="min-width: 2rem">
          <fa-icon
            icon="mdi:chevron-right"
            class="w-4" />
        </router-link>
      </div>
    </div>
  </nav>
</template>

<style lang="postcss"></style>
